//Контакты
import PageTitle from '../../components/page-title/pageTitle';
import { PhoneLink } from '../../components/phone_lev_template';

const ContactUs = () => {
    return (
        <div className="contact-us">
            <PageTitle title="Контакты" bottomText="Главная / Контакты" />
            <div className="static-page_text">
                <p>
                    <strong>
                        Если у Вас есть вопросы – свяжитесь с нами, мы всегда
                        рады!
                        <br />
                        С 10:00 до 21:00
                        <br />
                        Номер телефона: <PhoneLink />
                        <br />
                        Email: ultranout@gmail.com
                    </strong>
                </p>
                <p>
                    Просмотр интересующих моделей возможен в магазине нашего
                    розничного партнёра по адресу: город Москва, улица Сущёвский
                    вал, дом 5, стр 1а, павильон F52 - 200 метров от метро
                    "Савёловская" , с 11-00 до 20-30 ежедневно, необходимо
                    предварительное бронирование товара
                </p>
                <p>
                    Просим внимательно ознакомиться со схемой, если раньше не
                    были на территории торгового комплекса «Компьютерный» – она
                    большая и немудрено и заблудиться. Главный вход отмечен
                    «человечком» и далее по правому ряду примерно 2/3. Магазин –
                    справа.
                </p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        src={
                            `${process.env.REACT_APP_IMAGE_URL}/wp-content/uploads/2021/09/11808472134.04d985df2f654be2bb7397f29abe5f06.jpg`
                        }
                        alt="Схема"
                        style={{ maxWidth: '90%' }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
