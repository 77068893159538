import PageTitle from '../../components/page-title/pageTitle';
import { PhoneLink } from '../../components/phone_lev_template';

const DeliveryOptions = () => {
    return (
        <div className="delivery-methods">
            <PageTitle title="Доставка" bottomText="Главная / Доставка" />
            <div
                className="static-page_text"
                style={{
                    fontFamily: 'Lato, Arial, Helvetica, sans-serif',
                    fontWeight: 400,
                    fontSize: '14px',
                }}>
                <ol
                    >
                    <li>
                        Самовывоз возможен в магазине нашего партнёра по адресу
                        г. Москва, улица Сущевский вал, д. 5, стр. 1а, павильон
                        F52, ТЦ “Компьютерный”
                    </li>
                    <li>
                        Доставка по г. Москва осуществляется нашей курьерской
                        службой бесплатно, оплата товара возможна после
                        получения, время и адрес доставки утверждается с
                        менеджером.
                    </li>
                    <li>
                        Для городов Санкт-Петербург, Воронеж, Минск
                        (Белоруссия), Казань, Екатеринбург, Симферополь,
                        Севастополь появилась возможность получить товар через
                        экспедитора, это значит, что Вы можете оплатить товар
                        после получения и осмотра! Стоимость услуги– 3500р.,
                        оплата товара возможна только наличными или переводом на
                        карту, получение товара в офисе СДЕК.
                    </li>
                    <li>
                        Доставка СДЕКом после оплаты – это самый оптимальный по
                        цене и скорости вариант. Перед отправкой мы рады будем
                        сделать подтверждающие фото и видео ноутбука, проверки и
                        тесты – по запросу клиента. Посылка страхуется, оплата
                        производится переводом на карту физического лица Сбер,
                        Тиньков, Альфа или на счет нашего ИП.
                    </li>
                    <li>
                        Если удобного для Вас варианта нет в списке, Вы можете
                        проконсультироваться с менеджером и предложить свой
                        способ доставки, мы рассматриваем каждый случай
                        индивидуально!
                    </li>
                </ol>
                <h4
                    style={{
                        textAlign: 'center',
                        fontWeight: 700,
                        fontSize: '20px',
                    }}>
                    <cite>
                        Задайте нам вопрос: <PhoneLink />;
                        ultranout@gmail.com
                    </cite>
                </h4>
            </div>
        </div>
    );
};

export default DeliveryOptions;
