import styles from '../../compare.module.css';
import { handleCompareClick } from '../../../../helpers/handleCompareClick';
import { CloseButton, Table } from 'react-bootstrap';
import ProductCard from '../../../../components/product-card';
import { handleWishlistClick } from '../../../../helpers/handleWishlistClick';
import handleCartClick from '../../../../helpers/handleCartClick';
import { useEffect, useState } from 'react';
import { EmptyList } from '../empty-list';

export const CompareTable = () => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [wishlist, setWishlist] = useState(
        JSON.parse(localStorage.getItem('wishlist')),
    );

    // Эффект для получения списка продуктов из localStorage при монтировании компонента
    useEffect(() => {
        const compare = JSON.parse(localStorage.getItem('compare')) || [];
        setProducts(compare);
    }, []);

    return (
        <div style={{ overflowX: 'auto', maxWidth: '100%', margin: '0 auto' }}>
            {
                products.length === 0 ? <EmptyList /> : <Table className={styles.table} bordered>
                    <tbody>
                    {/*Я так и не придумал как сделать это все за один map, но по хорошему надо придумать*/}
                    <tr>
                        <td></td>
                        {products.length &&
                            products.map((product) => (
                                <td style={{ minWidth: '32.25rem', textAlign: 'center' }}>
                                    <label
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleCompareClick(
                                                product,
                                                products,
                                                setProducts,
                                            )
                                        }>
                                        <CloseButton />
                                        Удалить
                                    </label>
                                    <ProductCard
                                        product={product}
                                        verticalMode={true}
                                        hideDescription={true}
                                        onWishlistClick={() =>
                                            handleWishlistClick(
                                                product,
                                                wishlist,
                                                setWishlist,
                                            )
                                        }
                                        onCartClick={() =>
                                            handleCartClick(
                                                product,
                                                cart,
                                                setCart,
                                            )
                                        }
                                        isInWishlist={
                                            !wishlist.some(
                                                (wishlistProduct) =>
                                                    wishlistProduct.id ===
                                                    product.id,
                                            )
                                        }
                                        isInCart={
                                            !cart.some(
                                                (cartProduct) =>
                                                    cartProduct.id ===
                                                    product.id,
                                            )
                                        }
                                        isInCompare={
                                            !products.some(
                                                (compareProduct) =>
                                                    compareProduct.id ===
                                                    product.id,
                                            )
                                        }
                                        onCompareClick={() =>
                                            handleCompareClick(
                                                product,
                                                products,
                                                setProducts,
                                            )
                                        }
                                    />
                                </td>
                            ))}
                    </tr>
                    <tr>
                        <td style={{ minWidth: '30rem' }}>{'Описание'}</td>
                        {products.length &&
                            products.map((product) => (
                                <td
                                    dangerouslySetInnerHTML={{
                                        __html: product.description,
                                    }}
                                    style={{ width: '33.75rem' }}></td>
                            ))}
                    </tr>
                    <tr>
                        <td style={{ minWidth: '30rem' }}>
                            {'Арт'.toUpperCase()}
                        </td>
                        {products.length &&
                            products.map((product) => (
                                <td style={{ minWidth: '30rem' }}>
                                    {product.sku}
                                </td>
                            ))}
                    </tr>
                    <tr>
                        <td style={{ width: '30rem' }}>
                            {'Доступность'.toUpperCase()}
                        </td>
                        {products.length &&
                            products.map((product) => (
                                <td style={{ minWidth: '30rem' }}>
                                    {product.stockQuantity <= 0
                                        ? 'Доступно для предзаказа'
                                        : `В наличии: ${product.stockQuantity}`}
                                </td>
                            ))}
                    </tr>
                    </tbody>
                </Table>
            }
        </div>
    );
};
