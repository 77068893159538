import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";
import styles from "./image-carousel.module.css";
import { openFullscreen } from './utils';

const ImageCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const touchStartRef = useRef(null);
    const touchEndRef = useRef(null);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    const handleTouchStart = (e) => {
        touchStartRef.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e) => {
        touchEndRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        if (!touchStartRef.current || !touchEndRef.current) return;
        const distance = touchStartRef.current - touchEndRef.current;
        if (distance > 50) goToNext();
        if (distance < -50) goToPrevious();
        touchStartRef.current = null;
        touchEndRef.current = null;
    };

    useEffect(() => {
        const fullscreenContainer = document.querySelector(`.${styles.fullscreenContainer}`);
        if (fullscreenContainer) {
            const imageElement = fullscreenContainer.querySelector("img");
            if (imageElement) imageElement.src = images[currentIndex].src;
        }
    }, [currentIndex, images]);

    return (
        <div className={styles.carouselContainer}>
            {/* Изображение */}
            <div
                className={styles.imageWrapper}
                onTouchStart={handleTouchStart}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleTouchEnd}
            >
                <img
                    src={images[currentIndex].src}
                    alt={images[currentIndex].alt}
                    className={styles.mainImage}
                    onClick={() => openFullscreen(images[currentIndex].src, currentIndex, setCurrentIndex, images, handleTouchStart, handleTouchMove, handleTouchEnd)}
                />
                {/* Левая стрелочка */}
                <button
                    onClick={goToPrevious}
                    className={clsx(styles.arrowButton, styles.leftArrow)}
                >
                    &#8249;
                </button>
                {/* Правая стрелочка */}
                <button
                    onClick={goToNext}
                    className={clsx(styles.arrowButton, styles.rightArrow)}
                >
                    &#8250;
                </button>
            </div>

            {/* Иконки */}
            <div className={styles.thumbnailsContainer}>
                {images.map((image, index) => (
                    <img
                        key={index}
                        src={image.src}
                        alt={image.alt}
                        className={clsx(styles.thumbnail, {
                            [styles.activeThumbnail]: index === currentIndex,
                        })}
                        onClick={() => setCurrentIndex(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
