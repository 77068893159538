//Метод для открытия в полный экран. Поддерживает свайп на мобильных, поддерживает прокрутку кнопками клавиатуры
import styles from './image-carousel.module.css';
import clsx from 'clsx';

export const openFullscreen = (imageSrc, currentIndex, setCurrentIndex, images, handleTouchStart, handleTouchMove, handleTouchEnd) => {
    const fullscreenContainer = document.createElement("div");
    fullscreenContainer.className = styles.fullscreenContainer;

    const imageElement = document.createElement("img");
    imageElement.src = imageSrc;
    imageElement.className = styles.fullscreenImage;

    const leftArrow = document.createElement("button");
    leftArrow.className = clsx(styles.fullscreenArrow, styles.leftArrow);
    leftArrow.innerHTML = "&#8249;";
    leftArrow.onclick = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const rightArrow = document.createElement("button");
    rightArrow.className = clsx(styles.fullscreenArrow, styles.rightArrow);
    rightArrow.innerHTML = "&#8250;";
    rightArrow.onclick = (e) => {
        e.stopPropagation();
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    fullscreenContainer.appendChild(leftArrow);
    fullscreenContainer.appendChild(imageElement);
    fullscreenContainer.appendChild(rightArrow);

    fullscreenContainer.onclick = () => {
        document.body.removeChild(fullscreenContainer);
    };

    fullscreenContainer.ontouchstart = handleTouchStart;
    fullscreenContainer.ontouchmove = handleTouchMove;
    fullscreenContainer.ontouchend = handleTouchEnd;

    document.body.appendChild(fullscreenContainer);

    const handleKeyDown = (e) => {
        if (e.key === "ArrowLeft") {
            setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
        } else if (e.key === "ArrowRight") {
            setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
        } else if (e.key === "Escape") {
            document.body.removeChild(fullscreenContainer);
            document.removeEventListener("keydown", handleKeyDown);
        }
    };

    document.addEventListener("keydown", handleKeyDown);
};
