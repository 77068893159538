import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OverlayTrigger, Placeholder, Spinner, Tooltip } from 'react-bootstrap';
import CounterInput from 'react-counter-input';
import handleCartClick from '../../helpers/handleCartClick';
import { ModalOnAddToCart } from '../../components/modal-on-add-to-cart/modalOnAddToCart';
import ProductButtons from '../../components/product-buttons/productButtons';
import SocialIcons from '../../components/social-icons/socialIcons';
import ProductDetails from '../../components/product-details/productDetails';
import axios from 'axios';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './ProductPage.css'
import { useMediaQuery } from '../../helpers/hooks/useMediaQuery';
import ImageCarousel from '../../components/image-carousel/image-carousel';

const ProductPage = () => {
    const matches = useMediaQuery('(min-width: 768px)');
    const [showModal, setShowModal] = useState(false);

    const params = useParams();
    // console.log(typeof params.id);
    const [product, setProduct] = useState(
        {},
    );
    const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')));
    const [wishlist, setWishlist] = useState(
        JSON.parse(localStorage.getItem('wishlist')),
    );
    const [count, setCount] = useState(1);
    const isInWishlist = !wishlist.some(
        (wishlistProduct) => wishlistProduct.id === product?.id,
    );
    const isInCart = !cart.some(
        (wishlistProduct) => wishlistProduct.id === product?.id,
    );
    const [images, setImages] = useState([]);

    useEffect(() => {
        // const storedProduct = JSON.parse(
        //     localStorage.getItem('catalogue'),
        // ).rows.find((product) => product.id === parseInt(params.id));
        // console.log(storedProduct);
        // if (!storedProduct) {
            axios.get(`${process.env.REACT_APP_API_URL}/product?sku=${params.sku}`).then((response) => {
                console.log(response.data);
                setProduct(response.data);
                setImages(response.data.images);
            });
        // } else {
        //     setProduct(storedProduct);
        //     setImages(storedProduct.images);
        // }
    }, [params.sku]);

    const onCartClick = (product) => {
        handleCartClick(product, cart, setCart, count);
    };

    const onWishlistClick = () => {
        // Проверяем, есть ли продукт уже в списке
        const index = wishlist.indexOf(
            wishlist.find((item) => item.id === product.id),
        );
        //Метод indexOf возвращает -1 если не находит элемент
        if (index === -1) {
            // Добавляем продукт в конец массива
            const newWishlist = [...wishlist, { ...product }];
            // Обновляем состояние
            setWishlist(newWishlist);
            // Обновляем localStorage
            localStorage.setItem('wishlist', JSON.stringify(newWishlist));
        } else {
            //Создаем новый массив без этого элемента
            const newWishlist = [
                ...wishlist.slice(0, index),
                ...wishlist.slice(index + 1, wishlist.length),
            ];
            // Обновляем состояние
            setWishlist(newWishlist);
            // Обновляем localStorage
            localStorage.setItem('wishlist', JSON.stringify(newWishlist));
        }
    };

    // console.log(product);
    return (
        <>
        {product.name ? (
                <div
                    className="product-page"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '10px',
                    }}>
                    {showModal && (
                        <ModalOnAddToCart
                            show={showModal}
                            setShowModal={setShowModal}
                        />
                    )}
                    {!!product && (
                        <div
                            className="product-page-upper"
                            style={{
                                display: 'flex',
                                flexDirection: matches ? 'row' : 'column',
                            }}>
                            <div
                                className="carousel-wrapper"
                                style={{
                                    width: !matches && '100%',
                                }}>
                                <ImageCarousel images={product?.images}/>
                            </div>
                            <div
                                className="product-page_text"
                                style={{
                                    width: matches? '50%': '100%',
                                    paddingLeft: '15px',
                                }}>
                                <label>
                                    <Link
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                        }}
                                        to="/">
                                        Главная
                                    </Link>{' '}
                                    /
                                    <Link
                                        style={{
                                            textDecoration: 'none',
                                            color: 'black',
                                        }}
                                        to={`/catalogue/${product?.category?.slug}`}>
                                        {' '}
                                        {product.category?.name}
                                    </Link>{' '}
                                    /<strong> {product?.name}</strong>
                                </label>
                                <h3
                                    className="product-page_text-header"
                                    style={{
                                        paddingTop: '10px',
                                    }}>
                                    {product?.name}
                                </h3>
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>
                                            Цена актуальна при оплате наличными
                                        </Tooltip>
                                    }
                                    placement={'top'}>
                                    <div>
                                        <div className="product-card_text-price crossed">
                                            <del
                                                style={{
                                                    color: 'rgb(245 102 34)',
                                                    fontSize: '18px',
                                                    fontWeight: '400',
                                                }}>
                                        <span style={{ color: 'black' }}>
                                            {product.regularPrice ? Number(product?.regularPrice).toLocaleString('en-US') : <Placeholder xs={6}/>} &#8381;
                                        </span>
                                            </del>
                                        </div>
                                        <div
                                            className="product-page_text-price"
                                            style={{
                                                color: 'rgb(245 102 34)',
                                                fontWeight: '600',
                                                fontSize: '24px',
                                                maxWidth: 'fit-content',
                                            }}>
                                            {product.salePrice ? Number(product?.salePrice).toLocaleString('en-US') : <Placeholder xs={6}/>} &#8381;
                                        </div>
                                    </div>
                                </OverlayTrigger>
                                <div
                                    style={{
                                        width: '100%',
                                        maxHeight: '100%',
                                        paddingTop: '5px',
                                        color: 'rgb(119 119 119)',
                                        fontSize: '14px',
                                    }}
                                    className="product-card_page-description"
                                    dangerouslySetInnerHTML={{
                                        __html: product?.shortDescription,
                                    }}></div>
                                {parseInt(product?.stockQuantity) > 0 ? (
                                    <label className="product-page_text-available">
                                <span
                                    style={{ color: 'rgba(245, 102, 34, 1)' }}>
                                    {' '}
                                    &#x2713;{' '}
                                </span>
                                        В наличии: {product?.stockQuantity}
                                    </label>
                                ) : (
                                    <label className="product-page_text-available">
                                        Доступно для предзаказа
                                    </label>
                                )}
                                <div
                                    style={{
                                        width: '30%',
                                        paddingTop: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                    }}>
                                    <CounterInput
                                        min={1}
                                        count={count}
                                        onCountChange={(count) => setCount(count)}
                                    />
                                    <div
                                        className="buttons-wrapper"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}>
                                        <ProductButtons
                                            product={product}
                                            inCart={isInCart}
                                            onClickCart={() => {
                                                onCartClick(product);
                                                setShowModal(true);
                                            }}
                                            inWishlist={isInWishlist}
                                            onClickWishlist={() =>
                                                onWishlistClick(product)
                                            }
                                        />
                                    </div>
                                </div>
                                <div
                                    className="product-page_text-additional"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start',
                                        marginTop: '10px',
                                        paddingTop: '5px',
                                        fontSize: '14px',
                                        borderColor: 'rgba(227, 227, 227, 1)',
                                        borderTopWidth: '1px',
                                        borderTopStyle: 'solid',
                                    }}>
                                    <p className="product-page_text-articul">
                                        <strong>Артикул:</strong> {product?.sku}
                                    </p>
                                    <p className="product-page_text-category">
                                        <strong>Категория:</strong>{' '}
                                        {product.category?.name}
                                    </p>
                                    <p className="product-page_text-tag">
                                        <strong>Метка:</strong> {product?.tags}
                                    </p>
                                    <p className="product-page_text-social" style={{display: 'flex', flexDirection: 'row'}}>
                                        <strong>Мы в соцсетях:</strong>{' '}
                                        <SocialIcons size="xl" />
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="product-page_details-wrapper">
                        <ProductDetails details={product?.attributes}/>
                    </div>
                </div>
            ) : (
                <div className="spinner-wrapper" style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spinner />
                </div>
            )
        }
        </>
    );
};

export default ProductPage;
